import React from "react";
import { graphql, Link } from "gatsby";
import { Layout, PickupTitle, PageTitle } from "../components";

const PickupTemplate = ({ data }) => {
  const pickup = data.microcmsPickup;

  return (
    <Layout>
      <PageTitle title="PICKUP" subTitle="ピックアップ" />
      <PickupTitle
        title={pickup.title}
        modified={pickup.updatedAt}
        date={pickup.date}
      />
      <section className="max-w-5xl mx-auto px-4 mb-16 lg:mb-24">
        <div className="mb-8">
          <img
            className="object-cover w-full lg:px-16"
            src={pickup.featuredImage.url}
            alt={`${pickup.title} 画像`}
          />
        </div>
        <div
          className="tracking-wider leading-loose text-base"
          dangerouslySetInnerHTML={{
            __html: `${pickup.body}`,
          }}
        />
      </section>
      <Link
        to="/pickup"
        className="mb-16 lg:mb-24 block w-72 lg:w-80 mx-auto font-normal text-xs text-center tracking-widest py-4 lg:py-6 border border-primary-purple hover:bg-primary-purple hover:text-white transform duration-300"
      >
        BACK TO LIST
      </Link>
    </Layout>
  );
};

export default PickupTemplate;

export const pageQuery = graphql`
  query ($pickupId: String!) {
    microcmsPickup(pickupId: { eq: $pickupId }) {
      pickupId
      featuredImage {
        url
      }
      date(formatString: "Y.MM.DD")
      title
      body
      updatedAt
    }
  }
`;
